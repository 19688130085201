import { addEventListener } from "@oddcamp/js-utils/src/event"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"

addEventListener(document, `turbolinks:before-visit`, () => {
  removeClass(document.documentElement, `smooth-scroll`)
})

addEventListener(document, `turbolinks:load`, () => {
  addClass(document.documentElement, `smooth-scroll`)
})

addEventListener(document, `turbolinks:render`, () => {
  addClass(document.documentElement, `smooth-scroll`)
})
