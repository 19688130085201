import PdxDol from "@oddcamp/pdx-dol"

 const pdxDol = new PdxDol({
   params: {
     utmSource: `website`,
     utmMedium: `website`,
     utmContent: `button`,
     utmCampaign: `worldofdarkness`,
   },
 })

 pdxDol.start()
