import Cookies from "universal-cookie"

const ageGateCookies = new Cookies()

const AGE_GATE_COOKIE_NAME = `gate`
const AGE_GATE_ALLOWED_AGE = 18
const AGE_GATE_STRINGS_DEFAULTS = {
  title: `Enter your birth date.`,
  description: `Please enter your date of birth before proceeding.`,
  buttonLabel: `Submit`,
  notAllowed: {
    title: `We were unable to grant you access to the website.`,
    description: `Unfortunately, we were unable to grant you access to the website.`,
  },
}
let AGE_GATE_STRINGS
const AGE_GATE_CURRENT_YEAR = new Date().getFullYear()
const AGE_GATE_INPUTS = `select`

const setupBaseHtml = () => {
  const baseElement = document.createElement(`div`)
  const baseTemplate = () => `<div class="age-gate-overlay">
      <div id="age-gate-container" class="age-gate-container"></div>
    </div>`
  baseElement.id = `age-gate`
  baseElement.innerHTML = baseTemplate()
  document.body.appendChild(baseElement)

  return
}

const initAgeGate = (options) => {
  setupBaseHtml()

  if (options) {
    AGE_GATE_STRINGS = options
  } else {
    AGE_GATE_STRINGS = AGE_GATE_STRINGS_DEFAULTS
  }

  if (ageGateCookies.get(AGE_GATE_COOKIE_NAME) === `true`) {
    setAllowed()
    return
  }

  if (ageGateCookies.get(AGE_GATE_COOKIE_NAME) === `false`) {
    setNotAllowed()
    return
  }

  const days = Array(31)
    .join()
    .split(`,`)
    .map(
      function () {
        return this.i++
      },
      { i: 1 }
    )

  const months = Array(12)
    .join()
    .split(`,`)
    .map(
      function () {
        return this.i++
      },
      { i: 0 }
    )

  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )

  const template = () => `
      <div class="age-gate-title">
        <h1>${AGE_GATE_STRINGS[`title`]}</h1>
        <p>${AGE_GATE_STRINGS[`description`]}</p>
      </div>

      <div class="age-gate-papers">
        <form id="age-gate-form" class="signup-form">
          <ul id="age-gate-errors"></ul>

          <div class="form-dob">
            <div class="form-select">
              ${
                AGE_GATE_INPUTS === `select`
                  ? `
              <select name="age-gate-day" class="input">
                <option disabled selected>Day</option>
                ${days.map(
                  (day) =>
                    `<option value="${day}">${day
                      .toString()
                      .padStart(2, `0`)}</option>`
                )}
              </select>
              `
                  : `<input name="age-gate-day" class="input">`
              }
            </div>

            <div class="form-select">
              ${
                AGE_GATE_INPUTS === `select`
                  ? `
              <select name="age-gate-month" class="input">
                <option disabled selected>Month</option>
                ${months.map(
                  (month) =>
                    `<option value="${month + 1}">${(month + 1)
                      .toString()
                      .padStart(2, `0`)}</option>`
                )}
              </select>
              `
                  : `<input name="age-gate-month" class="input">`
              }
            </div>

            <div class="form-select">
              ${
                AGE_GATE_INPUTS === `select`
                  ? `
              <select name="age-gate-year" class="input">
                <option disabled selected>Year</option>
                ${range(
                  AGE_GATE_CURRENT_YEAR,
                  AGE_GATE_CURRENT_YEAR - 100,
                  -1
                ).map(
                  (year) => `
                  <option value="${year}">
                    ${year}
                  </option>`
                )}
              </select>
              `
                  : `<input name="age-gate-year" class="input">`
              }
            </div>

          </div>

          <div>
            <input type="submit" class="button" value="${
              AGE_GATE_STRINGS[`buttonLabel`]
            }">
          </div>
        </form>
      </div>
      `

  document.body.classList.add(`age-gate-active`)
  document.getElementById(`age-gate-container`).innerHTML = template()
  const form = document.getElementById(`age-gate-form`)
  form.onsubmit = (event) => handleSubmit(event)
}

export { initAgeGate }

const setError = (errors) => {
  const errorList = document.getElementById(`age-gate-errors`)
  let list = ``
  errorList.innerHTML = ``

  errors.map((error) => (list += `<li>${error}</li>`))

  errorList.innerHTML = list
}

const setNotAllowed = () => {
  const ageGate = document.getElementById(`age-gate`)
  const form = document.getElementById(`age-gate-form`)

  if (form) {
    ageGate.querySelector(`.age-gate-papers`).removeChild(form)
  }
  ageGate.querySelector(
    `.age-gate-overlay`
  ).innerHTML = `<div class="age-gate-title">
      <h1>${AGE_GATE_STRINGS[`notAllowed`][`title`]}</h1>
      <p>${AGE_GATE_STRINGS[`notAllowed`][`description`]}</p>
      </div>`
  document.body.classList.add(`age-gate-active`)

  return
}

const setAllowed = () => {
  const ageGate = document.getElementById(`age-gate`)

  ageGate.parentNode.removeChild(ageGate)
  document.body.classList.remove(`age-gate-active`)

  if (!window.location.hash) {
    window.scrollTo(0, 0)
  }

  return
}

const handleSubmit = (event) => {
  event.preventDefault()

  const values = {
    year: document.getElementsByName(`age-gate-year`)[0].value,
    month: document.getElementsByName(`age-gate-month`)[0].value,
    day: document.getElementsByName(`age-gate-day`)[0].value,
  }

  const allowed = checkAge(
    values.year,
    values.month.toString().padStart(2, `0`),
    values.day.toString().padStart(2, `0`)
  )

  if (typeof allowed === `boolean` && allowed) {
    ageGateCookies.set(AGE_GATE_COOKIE_NAME, true, {
      path: `/`,
      maxAge: 5184000, // 60 days, set in seconds
      secure: true,
      sameSite: `None`,
    })
    setAllowed()
  } else if (allowed === `invalid_date`) {
    setError([`Invalid date`])
  } else {
    ageGateCookies.set(AGE_GATE_COOKIE_NAME, false, {
      path: `/`,
      maxAge: 5184000, // 60 days, set in seconds
      secure: true,
      sameSite: `None`,
    })
    setNotAllowed()
  }

  return
}

const checkAge = (year, month, day) => {
  const date = new Date()
  date.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day))
  const nowDate = new Date()
  nowDate.setFullYear(nowDate.getFullYear() - AGE_GATE_ALLOWED_AGE)

  if (
    !(
      day.toString().length === 2 &&
      month.toString().length === 2 &&
      year.toString().length === 4
    )
  ) {
    return `invalid_date`
  }

  if (
    !(
      date.getFullYear() === parseInt(year) &&
      date.getMonth() === parseInt(month) - 1 &&
      date.getDate() === parseInt(day)
    ) ||
    date.getTime() !== date.getTime()
  ) {
    return `invalid_date`
  }

  if (nowDate - date < 0) {
    return false
  }

  return true
}
