import Cookies from "js-cookie"
import { getElements } from "@oddcamp/js-utils/src/selector"
import { removeClass } from "@oddcamp/js-utils/src/attribute"

export function initCookieModal() {
  // manually set FB Pixel
  Cookies.set(`_fbp`, `true`, { expires: 365 })

  const cookieObjects = Cookies.get()
  const cookieArray = Object.keys(cookieObjects)
  // array of targeting cookies to check for
  const cookieCheck = [`_fbp`, `IDE`, `m_pixel_ratio`]

  let targetingCookies = false

  for (let i = 0; i < cookieCheck.length; i++) {
    if (cookieArray.indexOf(cookieCheck[i]) > -1) {
      targetingCookies = true
      break
    }
  }

  function compileHtml() {
    if (targetingCookies) {
      targetingCookies = `
        <h6>Targeting cookies</h6>
        <div class="pdx-cookie-form-checkbox">
          <input type="checkbox" name="targeting_optout" value="true" checked id="pdx-cookie-form-checkbox-input">
          <span class="trigger"></span>
        </div>
        <p>Advertisers and/or other content providers that may appear on our website may also use cookies that are not sent by us. Such advertisements or content may use cookies to help track and target the interests of users of the website in order to present customized and personalized advertisements or other messages that the user might find interesting. These cookies uniquely identify your browser and Internet device. Such cookies may even impact your experience of other websites you may visit subsequently. If you do not allow these cookies, you will experience less targeted advertising. Paradox is not responsible for any of our advertisers or content providers cookies and we will always require your active consent for our own targeting cookies.</p>

        <div class="pdx-cookie-form__accordion">
          <button class="pdx-cookie--toggle" type="button" role="button" data-target="pdx-cookie-form-list--targeting">
            View cookies
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
          </button>
          <ul class="pdx-cookie-form-list pdx-cookie-form-list--targeting">
            ${(() => {
              if (cookieArray.includes(`IDE`)) {
                return `
                    <li>
                      <h6>IDE</h6>
                      <p>To verify that a user made a purchase after clicking or seeing an ad of Google Display Network; to retarget (show advertising to users who visited the page) and to create new target audiences.</p>
                    </li>
                  `
              } else {
                return ``
              }
            })()}
            ${(() => {
              if (
                cookieArray.includes(`_fbp`) ||
                cookieArray.includes(`m_pixel_ratio`)
              ) {
                return `
                    <li>
                      <h6>_fbp, m_pixel_ratio</h6>
                      <p>To verify that a user made a purchase after clicking or seeing an ad of Facebook; to retarget (show advertising to users who visited the page) and to create new target audiences.</p>
                    </li>
                  `
              } else {
                return ``
              }
            })()}
            ${
              (() => {
                if(cookieArray.includes('_rdt_uuid')) {
                  return `
                    <li>
                      <h6>_rdt_uuid</h6>
                      <p>To verify that a user made a purchase after clicking or seeing an ad of Reddit; to retarget (show advertising to users who visited the page) and to create new target audiences.</p>
                    </li>
                  `;
                } else {
                  return ``;
                }
              })()
            }
          </ul>
        </div>
      `
    }

    return `
      <section id="#pdx-cookie" class="pdx-cookie-container pdx-cookie--container --active">
        <div class="pdx-cookie-bottom-bar">
          <div class="pdx-cookie-bottom-bar__content">
            <h5>Stop! Cookie time</h5>
            <p>We use cookies (sadly not the edible ones) to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services. You consent to our cookies if you continue to use this website.</p>
          </div>

          <div class="pdx-cookie-bottom-bar__action">
            <button class="pdx-cookie--toggle" type="button" role="button" data-target="pdx-cookie-form">
              Manage cookies
            </button>

            <button class="pdx-cookie--accept --first" type="button" role="button">
              I accept
            </button>
          </div>

          <button class="pdx-cookie-bottom-bar__toggle pdx-cookie--toggle --inactive" type="button" role="button" data-target="pdx-cookie-container"></button>
        </div>

        <div class="pdx-cookie-form pdx-cookie--container">
          <div class="pdx-cookie-form__content">
            <h5>About Cookies</h5>
            <p class="pdx-cookie-styled-p">We use cookies (sadly not the edible ones) and so-called pixel tags to collect information about visitors on our websites. Pixel tags are invisible tags placed on certain pages of our websites, but unlike cookies, not saved on your computer. A pixel tag triggers a cookie when you visit a website. A cookie is a small piece of data sent from the website to your web browser and may be stored on your computer's hard drive. Cookies allow us to recognize your computer while you are on the website and help customize your online experience and make it more convenient for you. Cookies are also useful in allowing more efficient log-in for users, tracking transaction histories, and preserving information between sessions. The information collected from cookies may also be used to improve the functionality of the website and to measure traffic patterns.</p>

            <p class="pdx-cookie-styled-p">Example of improved experience: If you visit our forum, you will not be asked for your password every time you visit, but rather every now and then, or when you clear your cookies manually.</p>
            <p class="pdx-cookie-styled-p">There are two main kinds of cookies: session cookies and persistent cookies. Session cookies are deleted from your computer when you close your browser, whereas persistent cookies remain stored on your computer until deleted, or until they reach their expiry date. You are entitled to choose whether or not you would like to use cookies. If you do not approve to the use of cookies, please be sure to opt out, as in block or disable them using your browser settings as described below or the opt-out link provided to you on your first visit to the website.</p>

            <h6>Strictly necessary cookies</h6>
            <p>These cookies are necessary for the website to function. They are usually set in response to actions made by you which amount to a request for services, such as logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the website might then not work. These cookies do not store any Personal Information.</p>

            <h6>Performance cookies</h6>
            <p>These cookies allow us to count visits and traffic sources in order to measure and improve the performance of the website. These cookies help us to know which pages are the most and least popular and how visitors move around the website. All information these cookies collect is aggregated and therefore anonymous. You can set your browser to block or alert you about these cookies. If you do so, we will not know when you have visited our website, and will not be able to monitor its performance.</p>

            <div class="pdx-cookie-form__accordion">
              <button class="pdx-cookie--toggle" type="button" role="button" data-target="pdx-cookie-form-list--performance">
                View cookies
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>
              </button>
              <ul class="pdx-cookie-form-list pdx-cookie-form-list--performance">
                <li>
                  <h6>_gat_gtag_UA, _gcl_au, _ga, _gid</h6>
                  <p>Google Analytics; stores unique, randomly generated string that gets stored in the browsers cookies, so subsequent visits to the same site can be associated with the same user.</p>
                </li>
                <li>
                  <h6>NID, SSID</h6>
                  <p>Google cookie; contains a unique ID Google uses to remember your preferences and other information, such as your preferred language.</p>
                </li>
                <li>
                  <h6>SID, HSID</h6>
                  <p>Google cookie; contains digitally signed and encrypted records of a user’s Google account ID and most recent sign-in time. Used for security.</p>
                </li>
                <li>
                  <h6>1P_JAR, SIDCC, APISID, SAPISID, NID, APISID, SID, HSID, SSID, SAPISID</h6>
                  <p>These cookies are used to collect information about the use of our website by visitors. We use the information to prepare reports and to improve the site. This cookie is only associated to an anonymous user and to his / her computer / device without providing references that allow knowing personal data. It collect the number of visitors to the site, the duration of visits, the browser, the type of terminal, the place of origin of the visitors and the pages visited.</p>
                </li>
              </ul>
            </div>

            <h6>Functionality cookies</h6>
            <p>These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services have been added to the website. If you do not allow these cookies, some or all of these services may not function properly.</p>

            ${targetingCookies || ``}

            <p>Please note that most web browser applications (such as Microsoft Edge, Mozilla Firefox and Google Chrome) have features that can notify you when you receive a cookie or prevent cookies from being sent. In Microsoft Edge, you can refuse all cookies by clicking "Tools", "Internet Options", "Privacy", and selecting "Block all cookies" using the sliding selector. In Firefox, you can adjust your cookies by clicking "Tools", "Options" and "Privacy". In Google Chrome, you can also adjust your cookies by clicking on "Settings", "Show advanced settings", "Content settings" and on the "Privacy" section.</p>

            <p class="pdx-cookie-styled-p">If you disable cookies, however, you may not be able to use certain personalized functions of the website.</p>

            <button class="pdx-cookie--accept" type="button" role="button">Save cookie settings</button>
          </div>
        </div>

        <button class="pdx-cookie-bottom-bar__toggle pdx-cookie--toggle" type="button" role="button" data-target="pdx-cookie-container"></button>
      </section>
    `
  }

  document.body.insertAdjacentHTML(`beforeend`, compileHtml())

  // general toggles
  const cookiesTrigger = getElements(`.pdx-cookie--toggle`)

  cookiesTrigger.forEach((trigger) => {
    trigger.addEventListener(`click`, (e) => {
      e.preventDefault()

      if (trigger.classList.contains(`--inactive`)) {
        return
      }

      const targets = getElements(`.` + trigger.getAttribute(`data-target`))

      targets.forEach((target) => {
        const isActive = target.classList.contains(`--active`)
        removeClass(target, `--active`)
        target.classList.toggle(`--active`, !isActive)
      })
    })
  })

  // cookie specific toggles

  const cookiesAccept = getElements(`.pdx-cookie--accept`)
  const targetingAccept = document.getElementById(
    `pdx-cookie-form-checkbox-input`
  )
  const containers = getElements(`.pdx-cookie--container`)

  cookiesAccept.forEach((cookieAccept) => {
    cookieAccept.addEventListener(`click`, (e) => {
      e.preventDefault()

      containers.forEach((container) => {
        removeClass(container, `--active`)
      })

      removeClass(`.pdx-cookie-bottom-bar__toggle`, `--inactive`)

      cookiesAccept.forEach((button) => {
        button.classList.add(`--invert`)

        if (button.classList.contains(`--first`)) {
          button.textContent = `Accepted`
        }
      })

      Cookies.set(`cookies_accepted`, `cookies-accepted`, { expires: 365 })

      if (targetingAccept && targetingAccept.checked) {
        Cookies.remove(`targeting_optout`, { path: `` })
      } else if (targetingAccept) {
        Cookies.set(`targeting_optout`, `targeting-optout`, { expires: 365 })
      }
    })
  })

  if (Cookies.get(`cookies_accepted`)) {
    removeClass(`.pdx-cookie-container`, `--active`)
    removeClass(`.pdx-cookie-bottom-bar__toggle`, `--inactive`)

    cookiesAccept.forEach((cookieAccept) => {
      cookieAccept.classList.add(`--invert`)
      if (cookieAccept.classList.contains(`--first`)) {
        cookieAccept.textContent = `Accepted`
      }
    })
  }

  if (targetingAccept && Cookies.get(`targeting_optout`)) {
    targetingAccept.checked = false

    // remove targeting cookies
    Cookies.remove(`_fbp`, { path: `` })
    Cookies.remove(`IDE`, { path: `` })
    Cookies.remove(`m_pixel_ratio`, { path: `` })
    Cookies.remove('_rdt_uuid', { path: '' });
  }
}
