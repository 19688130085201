import init from "./utils/init"

import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"
import { getElements } from "@oddcamp/js-utils/src/selector"
import {
  addClass,
  removeClass,
  toggleClass,
} from "@oddcamp/js-utils/src/attribute"
import { addEventListener } from "@oddcamp/js-utils/src/event"
import { debounce } from "@oddcamp/js-utils/src/function"
import { initCookieModal } from "./pdx-cookies.js"
import { ResponsiveVideos } from "./utils/vanilla-fitvids.js"
import { initAgeGate } from "./utils/age-gate"
import { Swiper, Navigation, Pagination, Autoplay, Keyboard } from "swiper"

import "./utils/turbolinks-smooth-scroll"

import "./pdx-dol.js"
// import { get } from "js-cookie"

let ytplayer

window.onYouTubeIframeAPIReady = (video_id = ``) => {
  if (!window.YT || window.ytplayer) {
    return
  }

  if (video_id.length > 1) {
    ytplayer = new YT.Player(`youtube-embed`, {
      videoId: video_id,
      playerVars: { origin: window.location.origin },
    })
  }
}

init(() => {
  let currWidth = document.body.offsetWidth

  onYouTubeIframeAPIReady && onYouTubeIframeAPIReady()

  // initAgeGate()

  initCookieModal()

  ResponsiveVideos()

  initImageGallery()

  initNavDropdowns()

  initVideoOverlays()

  initBackToTop()

  initAccountConfirmButton()

  initSwiper()
  
  initStickyNav()

  const initResizeObserver = new ResizeObserver(
    debounce(1000, (entries) => {
      entries.forEach((entry) => {
        if (currWidth != entry.contentRect.width) {
          currWidth = entry.contentRect.width
          initStickyNav()
        }
      })
    })
  )

  initResizeObserver.observe(document.body)

  addEventListener(`.-menu`, `click`, (e) => {
    e.preventDefault()

    toggleClass(`.menu-button`, `active`)
    toggleClass(`#links-mobile`, `active`)
    toggleClass(`.header-nav--mobile`, `active`)
  })

  addEventListener(`.js--mobile-dropdown`, `click`, (e) => {
    e.preventDefault()

    toggleClass(e.currentTarget.parentElement, `active`)
  })
})

const initStickyNav = () => {
  const nav = document.querySelector(`nav.header-nav`)
  const navMobile = document.querySelector(`nav.header-nav--mobile`)
  const current = nav.offsetParent ? nav : navMobile
  const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle(`is-sticky`, e.intersectionRatio < 1),
    { threshold: [1] }
  )

  observer.observe(current)
}

const initNavDropdowns = () => {
  document.addEventListener(`click`, (e) => {
    if (!Array.from(e.target.parentElement.classList).includes(`-dropdown`)) {
      removeClass(`.-dropdown`, `active`)
    }
  })

  addEventListener(`.-dropdown > button`, `click`, (e) => {
    e.preventDefault()

    if (
      !Array.from(e.currentTarget.parentElement.classList).includes(`active`)
    ) {
      removeClass(`.-dropdown`, `active`)
    }
    toggleClass(e.currentTarget.parentElement, `active`)
  })
}

const initImageGallery = () => {
  function ChangeFeaturedImage(currentTarget) {
    const currentIndex = currentTarget.getAttribute(`data-featured`)
    const allFeatured = currentTarget.parentElement.parentElement.parentElement.querySelectorAll(
      `[data-featured]`
    )
    const featured = currentTarget.parentElement.parentElement.parentElement.querySelector(
      `[data-featured="${currentIndex}"]`
    )
    removeClass(allFeatured, `-active`)
    addClass(featured, `-active`)
  }
  function SelectImage(elem) {
    removeClass(
      elem.parentElement.parentElement.querySelectorAll(`.image-item--prev`),
      `image-item--prev`
    )
    removeClass(
      elem.parentElement.parentElement.querySelectorAll(`.image-item--active`),
      `image-item--active`
    )
    removeClass(
      elem.parentElement.parentElement.querySelectorAll(`.image-item--next`),
      `image-item--next`
    )
    removeClass(
      elem.parentElement.parentElement.querySelectorAll(
        `.image-item--extra-next`
      ),
      `image-item--extra-next`
    )
    removeClass(
      elem.parentElement.parentElement.querySelectorAll(
        `.image-item--extra-prev`
      ),
      `image-item--extra-prev`
    )
    addClass(elem.parentElement, `image-item--active`)

    if (elem.parentElement.previousElementSibling) {
      addClass(elem.parentElement.previousElementSibling, `image-item--prev`)
    }
    if (
      elem.parentElement == elem.parentElement.parentElement.firstElementChild
    ) {
      addClass(
        elem.parentElement.parentElement.lastElementChild,
        `image-item--prev`
      )
    }
    if (elem.parentElement.nextElementSibling) {
      addClass(elem.parentElement.nextElementSibling, `image-item--next`)
    }
    if (
      elem.parentElement == elem.parentElement.parentElement.lastElementChild
    ) {
      addClass(
        elem.parentElement.parentElement.firstElementChild,
        `image-item--next`
      )
    }
  }

  addEventListener(`.js--change-featured-image`, `click`, (e) => {
    e.preventDefault()

    SelectImage(e.currentTarget)
    ChangeFeaturedImage(e.currentTarget)
  })

  addEventListener(`.js--next-image`, `click`, (e) => {
    e.preventDefault()

    const selectedItem = e.currentTarget.parentElement.parentElement.querySelector(
      `.image-item--active`
    )

    if (selectedItem.parentElement.lastElementChild == selectedItem) {
      SelectImage(
        selectedItem.parentElement.firstElementChild.querySelector(`button`)
      )
      ChangeFeaturedImage(
        selectedItem.parentElement.firstElementChild.querySelector(`button`)
      )
    } else {
      SelectImage(selectedItem.nextElementSibling.querySelector(`button`))
      ChangeFeaturedImage(
        selectedItem.nextElementSibling.querySelector(`button`)
      )
    }
  })

  addEventListener(`.js--prev-image`, `click`, (e) => {
    e.preventDefault()

    const selectedItem = e.currentTarget.parentElement.parentElement.querySelector(
      `.image-item--active`
    )

    if (selectedItem.parentElement.firstElementChild == selectedItem) {
      SelectImage(
        selectedItem.parentElement.lastElementChild.querySelector(`button`)
      )
      ChangeFeaturedImage(
        selectedItem.parentElement.lastElementChild.querySelector(`button`)
      )
    } else {
      SelectImage(selectedItem.previousElementSibling.querySelector(`button`))
      ChangeFeaturedImage(
        selectedItem.previousElementSibling.querySelector(`button`)
      )
    }
  })
}

const initVideoOverlays = () => {
  if (document.querySelector(`.js--trailer-trigger`)) {
    const trailerButtons = getElements(`.js--trailer-trigger`)
    const trailerOverlay = document.querySelector(`.trailer`)
    const trailerClose = document.querySelector(`.trailer__close`)

    trailerButtons.forEach((trailerButton) => {
      trailerButton.addEventListener(`click`, (e) => {
        e.preventDefault()

        const trailerId = e.currentTarget.getAttribute(`data-video`)

        if (ytplayer) {
          ytplayer.loadVideoById(trailerId)
        } else {
          onYouTubeIframeAPIReady(trailerId).playVideo()
        }

        trailerOverlay.classList.add(`trailer--visible`)
      })
    })

    trailerClose.addEventListener(`click`, (e) => {
      e.preventDefault()

      if (ytplayer) {
        ytplayer.stopVideo()
      }
      trailerOverlay.classList.remove(`trailer--visible`)
    })

    addEventListener(document, `keydown`, (event) => {
      if (event.key === `Escape` || event.keyCode === 27) {
        if (ytplayer) {
          ytplayer.stopVideo()
        }
        trailerOverlay.classList.remove(`trailer--visible`)
      }
    })
  }
}

const initBackToTop = () => {
  addEventListener(`.js--back-to-top`, `click`, (e) => {
    e.preventDefault()

    window.scrollTo({ top: 0 })
  })
}

initSmartOutline([
  `input:focus`,
  `button:focus`,
  `textarea:focus`,
  `select:focus`,
  `.form-checkbox label`,
])

const initAccountConfirmButton = () => {
  if (getElements(`.js--confirm`)) {
    addEventListener(`.js--confirm`, `click`, (event) => {
      event.currentTarget.disabled = true
      event.currentTarget.value = `Confirmation sent!`
    })
  }
}

// Slider / swiper
const initSwiper = () => {
  if (getElements(`.js--slider`)) {
    Swiper.use([Navigation, Pagination, Autoplay, Keyboard])

    const swiper = new Swiper(
      `.front-page-hero > .swiper-container`,
      {
        loop: true,
        autoplay: {
          delay: 4500,
        },
        speed: 500,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        keyboard: true,
        navigation: {
          nextEl: `.swiper-button-next`,
          prevEl: `.swiper-button-prev`,
        },
        pagination: {
          el: `.swiper-pagination`,
          type: `fraction`,
        },
      }
    )
  }
}
