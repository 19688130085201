// https://gist.github.com/davatron5000/e9ef20f1d2ba4d9099711064c644d155
// Vanilla version of FitVids
// Still licencened under WTFPL
//
// Not as robust and fault tolerant as the jQuery version.
// It's BYOCSS.
// And also, I don't support this at all whatsoever.

export function ResponsiveVideos() {
  // List of Video Vendors embeds you want to support
  const players = [
    `iframe[src*="youtube.com"]`,
    `iframe[src*="vimeo.com"]`,
    `iframe[src*="twitch.tv"]`,
  ]

  // Select videos
  const fitVids = document.querySelectorAll(players.join(`,`))

  // If there are videos on the page...
  if (fitVids.length) {
    // Loop through videos
    for (let i = 0; i < fitVids.length; i++) {
      // Get Video Information
      const fitVid = fitVids[i]
      const width = fitVid.getAttribute(`width`)
      const height = fitVid.getAttribute(`height`)
      const aspectRatio = height / width
      const parentDiv = fitVid.parentNode

      // Wrap it in a DIV
      const div = document.createElement(`div`)
      div.className = `video-container`
      div.style.paddingBottom = aspectRatio * 100 + `%`
      parentDiv.insertBefore(div, fitVid)
      fitVid.remove()
      div.appendChild(fitVid)

      // Clear height/width from fitVid
      fitVid.removeAttribute(`height`)
      fitVid.removeAttribute(`width`)
    }
  }
}
